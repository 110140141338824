import {useState, useEffect} from 'react';
import './App.css';
import Header from './Header.jsx';
import Utility from './Utility.jsx';
import Body from './Body.jsx';

var breadcrumbIndex = 0;
var breadcrumbIndexTop = 0;
var breadcrumbs = [{what: 'home', detail: null, scroll: 0}];

export default function App() {
  useEffect(() => {
    fetchResume();
  }, []);

  function fetchResume() {
    let resume = 'resume.json';
    if((window.location.search !== undefined) && (window.location.search !== null) && (window.location.search !== '')) {
      const re = /^.(.*)/;
      let res = re.exec(window.location.search);
      const args = res[1].split('&');
      const kvre = /(.*)=(.*)/;
      for(let i = 0; i < args.length; i++) {
        const kv = kvre.exec(args[i]);
        if(kv[1] === 'who') {
          resume = `${kv[2]}.json`;
          break;
        }
      }
    }

    fetch(resume)
      .then((res) => res.json())
      .then((data) => {setResume(data); if((data.style !== undefined) && (data.style !== null)) setStyle(data.style);})
      .catch((e) => console.log(e));
  }

  function searchFor(detail) {
    const re = /[^a-z0-9 ]/g;
    const words = detail.toLowerCase().replace(re, '').split(' ');
    let found;

    function wordIsIn(word, o) {
      if(word === '') return false;

      let ret = false;

      if(typeof(o) === 'string') {
        const reWord = new RegExp(word);
        ret = reWord.test(o.toLowerCase().replace(re, ''));
      } else if(Array.isArray(o)) {
        for(let i = 0; i < o.length; i++) {
          if(wordIsIn(word, o[i])) {
            ret = true;
            break;
          }
        }
      } else if((o.type !== undefined) && (o.type !== null)) {
          if(o.type === 'note') {
            if((o.text !== undefined) && (o.text !== null)) ret = wordIsIn(word, o.text);
            if((! ret) && (o.note !== undefined) && (o.note !== null)) ret = wordIsIn(word, o.note);
          } else if(o.type === 'url') {
            if((o.text !== undefined) && (o.text !== null)) ret = wordIsIn(word, o.text);
            if((! ret) && (o.url !== undefined) && (o.url !== null)) ret = wordIsIn(word, o.url);
          }
      }

      return ret;
    }

    let ret = [];

    if((resume.home !== undefined) && (resume.home !== null) && (resume.home.detail !== undefined) && (resume.home.detail !== null)) {
      for(const i in words) {
        if(wordIsIn(words[i], resume.home.detail)) {
          ret.push({type: 'home', detail: null});
          break;
        }
      }
    }

    if((resume.skills !== undefined) && (resume.skills !== null)) {
      found = false;
      for(const skill in resume.skills) {
        if((skill !== '_key') && (skill !== '_list')) {
          if((resume.skills[skill].detail !== undefined) && (resume.skills[skill].detail !== null)) {
            for(const i in words) {
              if(wordIsIn(words[i], resume.skills[skill].detail)) {
                ret.push({type: 'skills', detail: skill});
                found = true;
                break;
              }
            }
          }
        }
      }
      if(! found) {
        for(const h in resume.skills._list) {
          const heading = resume.skills._list[h];
          for(const skill in heading) {
            for(const i in words) {
              if(wordIsIn(words[i], heading[skill][0])) {
                ret.push({type: 'skills', detail: null});
                found = true;
                break;
              }
            }
            if(found) break;
          }
          if(found) break;
        }
      }
    }

    if((resume.roles !== undefined) && (resume.roles !== null)) {
      found = false;
      for(const role in resume.roles) {
        if((role !== '_key') && (role !== '_list') && (resume.roles[role].detail !== undefined) && (resume.roles[role].detail !== null)) {
          for(const i in words) {
            if(wordIsIn(words[i], resume.roles[role].detail)) {
              ret.push({type: 'roles', detail: role});
              found = true;
              break;
            }
          }
        }
      }
      if(! found) {
        for(const r in resume.roles._list) {
          const role = resume.roles._list[r];

          for(const i in words) {
            if(wordIsIn(words[i], role[0])) {
              ret.push({type: 'roles', detail: role[0]});
              break;
            }
          }
        }
      }
    }

    if((resume.experience !== undefined) && (resume.experience !== null) && (resume.experience._list !== undefined) && (resume.experience._list !== null)) {
      found = false;
      for(const i in resume.experience._list) {
        const e = resume.experience._list[i];
        if((resume.experience[e] !== undefined) && (resume.experience[e] !== null)) {
          if((resume.experience[e].detail !== undefined) && (resume.experience[e].detail !== null)) {
            for(const i in words) {
              if(wordIsIn(words[i], resume.experience[e].detail)) {
                ret.push({type: 'experience', detail: e});
                found = true;
                break;
              }
            }
          }
        }
      }
      if(! found) {
        for(const i in resume.experience._list) {
          const e = resume.experience._list[i];
          if((resume.experience[e] !== undefined) && (resume.experience[e] !== null)) {
            if((resume.experience[e].name !== undefined) && (resume.experience[e].name !== null)) {
              for(const i in words) {
                if(wordIsIn(words[i], resume.experience[e].name)) {
                  ret.push({type: 'experience', detail: e});
                  break;
                }
              }
            }
          }
        }
      }
    }

    if((resume.qualifications !== undefined) && (resume.qualifications !== null)) {
      if((resume.qualifications._list !== undefined) && (resume.qualifications._list !== undefined)) {
        for(const q in resume.qualifications._list) {
          const qname = resume.qualifications._list[q];
          const qual = resume.qualifications[qname];
          if((qual !== undefined) && (qual !== null)) {
            found = false;
            if((qual.detail !== undefined) && (qual.detail !== null)) {
              for(const i in words) {
                if(wordIsIn(words[i], qual.detail)) {
                  ret.push({type: 'qualifications', detail: qname});
                  found = true;
                  break;
                }
              }
            }
          }
          if(! found) {
            if((qual.name !== undefined) && (qual.name !== null)) {
              for(const i in words) {
                if(wordIsIn(words[i], qual.name)) {
                  ret.push({type: 'qualifications', detail: qname});
                  break;
                }
              }
            }
          }
        }
      }
    }

    if((resume.notes !== undefined) && (resume.notes !== null)) {
      for(const n in resume.notes) {
        found = false;
        const note = resume.notes[n];
        if((note.detail !== undefined) && (note.detail !== null)) {
          for(const i in words) {
            if(wordIsIn(words[i], note.detail)) {
              ret.push({type: 'notes', detail: n});
              found = true;
              break;
            }
          }
        }
        if(! found) {
          if((note.name !== undefined) && (note.name !== null)) {
            for(const i in words) {
              if(wordIsIn(words[i], note.name)) {
                ret.push({type: 'notes', detail: n});
                break;
              }
            }
          }
        }
      }
    }

    return ret;
  }

  function handleAction(what, detail) {
    switch(what) {
      case 'back':
        if(breadcrumbIndex > 0) {
          let id = document.getElementById('body');
          if((id !== undefined) && (id !== null)) breadcrumbs[breadcrumbIndex].scroll = id.scrollTop;
          breadcrumbIndex--;
          setShow(breadcrumbs[breadcrumbIndex]);
          setBreadcrumbState({index: breadcrumbIndex, top: breadcrumbIndexTop});
        }
        break;

      case 'forward':
        if(breadcrumbIndex < breadcrumbIndexTop) {
          let id = document.getElementById('body');
          if((id !== undefined) && (id !== null)) breadcrumbs[breadcrumbIndex].scroll = id.scrollTop;
          breadcrumbIndex++;
          setShow(breadcrumbs[breadcrumbIndex]);
          setBreadcrumbState({index: breadcrumbIndex, top: breadcrumbIndexTop});
        }
        break;

    case 'xxx-home':
        breadcrumbIndex = 0;
        breadcrumbIndexTop = 0;
        breadcrumbs = [{what: 'home', detail: null, scroll: 0}];
        setShow({what: 'home', detail: null, scroll: 0});
        setBreadcrumbState({index: breadcrumbIndex, top: breadcrumbIndexTop});
        break;

    case 'style':
        if(show.what === 'skills')              setStyle((preState) => ({...preState, body: {...style.body, skills:         detail}}));
        else if(show.what === 'roles')          setStyle((preState) => ({...preState, body: {...style.body, roles:          detail}}));
        else if(show.what === 'roles')          setStyle((preState) => ({...preState, body: {...style.body, roles:          detail}}));
        else if(show.what === 'experience')     setStyle((preState) => ({...preState, body: {...style.body, experience:     detail}}));
        else if(show.what === 'qualifications') setStyle((preState) => ({...preState, body: {...style.body, qualifications: detail}}));
        else if(show.what === 'references')     setStyle((preState) => ({...preState, body: {...style.body, references:     detail}}));
        else if(show.what === 'search')         setStyle((preState) => ({...preState, body: {...style.body, search:         detail}}));
        break;

      case 'home':
      case 'skills':
      case 'roles':
      case 'experience':
      case 'qualifications':
      case 'references':
      case 'note':
        if((what !== show.what) || (detail !== show.detail)) {
          let id = document.getElementById('body');
          if((id !== undefined) && (id !== null)) breadcrumbs[breadcrumbIndex].scroll = id.scrollTop;
          breadcrumbIndex++;
          breadcrumbIndexTop = breadcrumbIndex;
          breadcrumbs[breadcrumbIndex] = {what: what, detail: detail, scroll: 0};
          setShow(breadcrumbs[breadcrumbIndex]);
          setBreadcrumbState({index: breadcrumbIndex, top: breadcrumbIndexTop});
        }
        break;

      case 'search':
        if((what !== 'search') || (breadcrumbs[breadcrumbIndex].searchString !== detail)) {
          let id = document.getElementById('body');
          if((id !== undefined) && (id !== null)) breadcrumbs[breadcrumbIndex].scroll = id.scrollTop;
          breadcrumbIndex++;
          breadcrumbIndexTop = breadcrumbIndex;
          breadcrumbs[breadcrumbIndex] = {what: what, detail: searchFor(detail), searchString: detail, scroll: 0};
          setShow(breadcrumbs[breadcrumbIndex]);
          setBreadcrumbState({index: breadcrumbIndex, top: breadcrumbIndexTop});
        }
        break;

      default:
    }
  }

  const [resume, setResume] = useState(null);
  const [show, setShow] = useState({what: 'home', detail: null, scroll: null});
  const [breadcrumbState, setBreadcrumbState] = useState({index: 0, top: 0});
  const [style, setStyle] = useState((resume !== undefined) && (resume !== null) && (resume.style !== undefined) && (resume.style !== null) ? resume.style : {header: null, body: {}, footer: null});

  if((resume === undefined) || (resume === null) || (resume.name === undefined) || (resume.name === null)) return null;
  document.title = resume.name;

  return (
    <div className='outer'>
      <div className='margin'></div>
      <div className='page'>
        <Header resume={resume} style={style} actionHandler={(what, detail) => handleAction(what, detail)} />
        <Utility style={style} show={show} breadcrumbState={breadcrumbState} actionHandler={(what, detail) => handleAction(what, detail)} />
        <Body id='body' show={show} resume={resume} style={style} actionHandler={(what, detail) => handleAction(what, detail)} />
      </div>
      <div className='margin'></div>
    </div>
  );
}
