import {useEffect} from 'react';
import './Body.css';

function v1BuildObject(o, resume, actionHandler) {
  if((o.type === undefined) || (o.type === null)) return null;

  let ret = null;
  switch(o.type) {
    case 'skill':
      if(o.skill === undefined) o.skill = null;
      if((resume.skills !== undefined) && (resume.skills !== null) && ((o.skill === null) || ((resume.skills[o.skill] !== undefined) && (resume.skills[o.skill] !== null)))) {
        if((o.skill == null) && ((o.text === undefined) || (o.text === null))) o.text = 'Skills';
        ret = <span className='bodyA' onClick={() => actionHandler('skills', o.skill)}>{(o.text !== undefined) && (o.text !== null) ? o.text : o.skill}</span>;
      } else
        ret = <span>{(o.text !== undefined) && (o.text !== null) ? o.text : o.skill}</span>;
      break;

    case 'role':
      if(o.role === undefined) o.role = null;
      if((resume.roles !== undefined) && (resume.roles !== null) && ((o.role === null) || ((resume.roles[o.role] !== undefined) && (resume.roles[o.role] !== null)))) {
        if((o.role == null) && ((o.text === undefined) || (o.text === null))) o.text = 'Roles';
        ret = <span className='bodyA' onClick={() => actionHandler('roles', o.role)}>{(o.text !== undefined) && (o.text !== null) ? o.text : o.role}</span>;
      } else
        ret = <span>{(o.text !== undefined) && (o.text !== null) ? o.text : o.role}</span>;
      break;

    case 'experience':
      if(o.experience === undefined) o.experience = null;
      if((resume.experience !== undefined) && (resume.experience !== null) && ((o.experience === null) || ((resume.experience[o.experience] !== undefined) && (resume.experience[o.experience] !== null)))) {
        if((o.experience == null) && ((o.text === undefined) || (o.text === null))) o.text = 'Experience';
        ret = <span className='bodyA' onClick={() => actionHandler('experience', o.experience)}>{(o.text !== undefined) && (o.text !== null) ? o.text : resume.experience[o.experience].name}</span>;
      } else
        ret = <span>{(o.text !== undefined) && (o.text !== null) ? o.text : o.experience}</span>;
      break;

    case 'qualification':
      if(o.qualification === undefined) o.qualification = null;
      if((resume.qualifications !== undefined) && (resume.qualifications !== null)) {
        if(o.qualification === null) {
          if((o.text === undefined) || (o.text === null)) o.text = 'Qualifications';
          ret = <span className='bodyA' onClick={() => actionHandler('qualifications', o.qualification)}>{o.text}</span>;
        } else {
          let qual = resume.qualifications[o.qualification];
          if((qual !== undefined) && (qual !== null)) {
            let det = qual.detail;
            if((det !== undefined) && (det !== null)) {
              ret = <span className='bodyA' onClick={() => actionHandler('qualifications', o.qualification)}>{qual.name}</span>;
            } else {
              ret = qual.nam;
            }
          } else {
            ret = <span>{(o.text !== undefined) && (o.text !== null) ? o.text : o.qualification}</span>;
          }
        }
      } else {
        ret = <span>{(o.text !== undefined) && (o.text !== null) ? o.text : o.qualification}</span>;
      }
      break;

    case 'reference':
      if(o.reference === undefined) o.reference = null;
      if((resume.references !== undefined) && (resume.references !== null) && ((o.reference === null) || ((resume.references[o.reference] !== undefined) && (resume.references[o.reference] !== null) && (resume.references[o.reference].name !== undefined) && (resume.references[o.reference].name !== null)))) {
        if((o.reference == null) && ((o.text === undefined) || (o.text === null))) o.text = 'References';
        ret = <span className='bodyA' onClick={() => actionHandler('references', o.reference)}>{(o.text !== undefined) && (o.text !== null) ? o.text : resume.references[o.reference].name}</span>;
      } else
        ret = <span>{(o.text !== undefined) && (o.text !== null) ? o.text : resume.references[o.reference].name}</span>;
      break;

    case 'note':
      if((resume.notes !== undefined) && (resume.notes !== null)) {
        let note = resume.notes[o.note];
        if((note !== undefined) && (note !== null)) {
          if((note.type === undefined) || (note.type === null) || (note.type === 'page')) {
            ret = <span className='bodyA' onClick={() => actionHandler('note', o.note)}>{(o.text !== undefined) && (o.text !== null) ? o.text : note.name}</span>;
          } else if(note.name === 'popup') {
          }
        }
      }
      break;

    case 'url':
      ret = <><a href={o.url} className='bodyA' target='_blank' rel='noreferrer noopener'>{(o.text !== undefined) && (o.text !== null) ? o.text : o.url}</a><img className='bodyURL' src='external.png' alt='External Link' /></>;
      break;

    case 'img':
      ret = <div className='bodyRemoteImg'><img src={o.img} alt='external' /></div>;
      break;

    case 'space':
      ret = <p />;
      break;

    default:
  }

  return ret;
}

function v1BuildDetails(detail, resume, actionHandler) {
  let ret = null;

  if((detail === undefined) || (detail === null)) {
    ret = null;
  } else if(typeof(detail) === 'string') {
    ret = <>{detail} </>;
  } else if(typeof(detail) === 'object') {
    if(Array.isArray(detail)) {
      for(const e in detail) {
        ret = <>{ret}{v1BuildDetails(detail[e], resume, actionHandler)}</>;
      }
    } else {
      ret = <>{ret}{v1BuildObject(detail, resume, actionHandler)}</>;
    }
  }

  return ret;
}

function v1Process(show, resume, style, actionHandler) {
  let ret = null;
  let what = show.what;
  let detail = show.detail;

  switch(what) {
    case 'home':
      if((resume.home !== undefined) && (resume.home !== null)) {
        let email = null;
        if((resume.email !== undefined) && (resume.email !== null)) email = <div className='bodyLR1'><div className='bodyLRInner'><span>Email</span><span><a href={'mailto:' + resume.email}>{resume.email}</a></span></div></div>;
        let phone = null;
        if((resume.phone !== undefined) && (resume.phone !== null)) phone = <div className='bodyLR1'><div className='bodyLRInner'><span>Phone</span><span>{resume.phone}</span></div></div>;
        let space = null;
        if((email !== null) || (phone !== null)) space = <p />;
        let date = null;
        if((resume.date !== undefined) && (resume.date !== null)) date = <><p /><div className='bodyText'>This resumé is current as at {resume.date}.</div></>;
        ret = <>
                <h1>{resume.name}</h1>
                {email}
                {phone}
                {space}
                <div className="bodyText">{v1BuildDetails(resume.home.detail, resume, actionHandler)}</div>
                {date}
              </>;
      }
      break;

    case 'skills':
      if(detail === null) {
        let key = resume.skills._key;
        let haveKey = (key !== undefined) && (key !== null);
        ret = <h1>Skills</h1>;
        for(let heading in resume.skills._list) {
          ret = <>{ret}<h2>{heading}</h2></>;
          if((style.body.skills === undefined) || (style.body.skills === null) || (style.body.skills === 'list')) {
            let row = 0;
            for(let item in resume.skills._list[heading]) {
              let skill = resume.skills._list[heading][item];
              let thisKey = null;
              if(haveKey && (key[skill[1]] !== undefined) && (key[skill[1]] !== null)) thisKey = key[skill[1]];
              if(haveKey) row++;
              ret = <>
                      {ret}
                      <div className={((row % 2) === 1 ? 'bodyOdd' : 'bodyEven') + ' bodyLR2'}>
                        <div className='bodyLRInner'>
                          {((resume.skills[skill[0]] !== undefined) && (resume.skills[skill[0]] !== null)) ? <span className="bodyA" onClick={() => actionHandler('skills', skill[0])}>{skill[0]}</span> : <span>{skill[0]}</span>}
                          <span className='bodyRight'>{thisKey}</span>
                        </div>
                      </div>
                    </>;
            }
          } else if(style.body.skills === 'grid') {
            let elements = null;
            for(let item in resume.skills._list[heading]) {
              let skill = resume.skills._list[heading][item];
              let thisKey = null;
              if(haveKey && (key[skill[1]] !== undefined) && (key[skill[1]] !== null)) thisKey = key[skill[1]];
              elements = <>
                      {elements}
                      <div className='bodyGridElement'>
                        <div className='bodyGridElementLarge'>{((resume.skills[skill[0]] !== undefined) && (resume.skills[skill[0]] !== null)) ? <span className="bodyA" onClick={() => actionHandler('skills', skill[0])}>{skill[0]}</span> : skill[0]}</div>
                        <div className='bodyGridElementSmall'>{thisKey}</div>
                      </div>
                    </>;
            }
            ret = <>{ret}<div className='bodyGrid2'><div className='bodyGrid'>{elements}</div></div></>;
          }
        }
      } else {
        if((resume.skills[detail] !== undefined) && (resume.skills[detail] !== null))  {
          ret = <>
                  <h1>{detail}</h1>
                  <div className='bodyText'>
                    {v1BuildDetails(resume.skills[detail].detail, resume, actionHandler)}
                  </div>
                </>;
        }
      }
      break;

    case 'roles':
      if(detail === null) {
        let key = resume.roles._key;
        let haveKey = (key !== undefined) && (key !== null);
        ret = <h1>Roles</h1>;
        if((style.body.roles === undefined) || (style.body.roles === null) || (style.body.roles === 'list')) {
          let row = 0;
          for(const item in resume.roles._list) {
            let role = resume.roles._list[item];
            let thisKey = null;
            if(haveKey && (key[role[1]] !== undefined) && (key[role[1]] !== null)) thisKey = key[role[1]];
            if(haveKey) row++;
            ret = <>
                    {ret}
                    <div className={((row % 2) === 1 ? 'bodyOdd' : 'bodyEven') + ' bodyLR1'}>
                      <div className='bodyLRInner'>
                        {((resume.roles[role[0]] !== undefined) && (resume.roles[role[0]] !== null)) ? <span className="bodyA" onClick={() => actionHandler('roles', role[0])}>{role[0]}</span> : <span>{role[0]}</span>}
                        <span className='bodyRight'>{thisKey}</span>
                      </div>
                    </div>
                  </>;
          }
        } else if(style.body.roles === 'grid') {
          let elements = null;
          for(const item in resume.roles._list) {
            let role = resume.roles._list[item];
            let thisKey = null;
            if(haveKey && (key[role[1]] !== undefined) && (key[role[1]] !== null)) thisKey = key[role[1]];
            elements = <>
                        {elements}
                        <div className='bodyGridElement'>
                          <div className='bodyGridElementLarge'>{((resume.roles[role[0]] !== undefined) && (resume.roles[role[0]] !== null)) ? <div className="bodyA" onClick={() => actionHandler('roles', role[0])}>{role[0]}</div> : <div className='bodyGridElementLarge'>{role[0]}</div>}</div>
                          <div className='bodyGridElementSmall'>{thisKey}</div>
                        </div>
                       </>;
          }
          ret = <>{ret}<div className='bodyGrid1'><div className='bodyGrid'>{elements}</div></div></>;
        }
      } else {
        if((resume.roles[detail] !== undefined) && (resume.roles[detail] !== null)) {
          ret = <>
                  <h1>{detail}</h1>
                  <div className='bodyText'>
                    {v1BuildDetails(resume.roles[detail].detail, resume, actionHandler)}
                  </div>
                </>;
        }
      }
      break;

    case 'experience':
      if(detail === null) {
        ret = <h1>Experience</h1>;
        if((style.body.experience === undefined) || (style.body.experience === null) || (style.body.experience === 'list')) {
          let row = 0;
          for(const i in resume.experience._list) {
            let e = resume.experience[resume.experience._list[i]];
            if((e !== undefined) & (e !== null)) {
              row++;
              ret = <>
                      {ret}
                      <div className={((row % 2) === 1 ? 'bodyOdd' : 'bodyEven') + ' bodyLR1'}>
                        <div className='bodyLRInner'>
                          <span className='bodyA' onClick={() => actionHandler('experience', resume.experience._list[i])}>{e.name}</span>
                          {(e.period !== undefined) && (e.period !== null) ? <span className='bodyRight'>{v1BuildDetails(e.period, resume, actionHandler)}</span> : null}
                        </div>
                      </div>
                    </>;
            }
          }
        } else if(style.body.experience === 'grid') {
          let elements = null;
          for(const i in resume.experience._list) {
            let e = resume.experience[resume.experience._list[i]];
            if((e !== undefined) & (e !== null)) {
              elements = <>
                          {elements}
                          <div className='bodyGridElement'>
                            <div className='bodyGridElementLarge'><span className='bodyA' onClick={() => actionHandler('experience', resume.experience._list[i])}>{e.name}</span></div>
                            <div className='bodyGridElementSmall'>{(e.period !== undefined) && (e.period !== null) ? <span>{v1BuildDetails(e.period, resume, actionHandler)}</span> : null}</div>
                          </div>
                         </>;
            }
          }
          ret = <>{ret}<div className='bodyGrid1'><div className='bodyGrid'>{elements}</div></div></>;
        }
      } else {
        ret = null;
        let e = resume.experience[detail];
        if((e !== undefined) && (e !== null)) {
          const industry = (e.industry !== undefined ? < div className='bodyLRInner'><span>Industry</span><span className='bodyRight'>{e.industry}</span></div> : null);
          const period = (e.period !== undefined ? <div className='bodyLRInner'><span>Period</span><span className='bodyRight'>{v1BuildDetails(e.period, resume, actionHandler)}</span></div> : null);
          const location = (e.location !== undefined ? <div className='bodyLRInner'><span>Location</span><span className='bodyRight'>{e.location}</span></div> : null);
          const pl = ((industry !== null) || (period !== null) || (location !== null)) ? <><div className='bodyLR1'>{industry}{period}{location}</div><p /></> : null;
          ret = <>
                  <h1>{e.name}</h1>
                  {pl}
                  <div className='bodyText'>
                    {v1BuildDetails(e.detail, resume, actionHandler)}
                  </div>
                </>;
        }
      }
      break;

    case 'qualifications':
      if(detail === null) {
        ret = <h1>Qualifications</h1>;
        if((style.body.qualifications === undefined) || (style.body.qualifications === null) || (style.body.qualifications === 'list')) {
          let row = 0;
          for(const i in resume.qualifications._list) {
            let q = resume.qualifications._list[i];
            let qual = resume.qualifications[q];
            if((qual !== undefined) && (qual !== null) && (qual.name !== undefined) && (qual.name !== null)) {
              row++;
              ret = <>
                      {ret}
                      <div className={((row % 2) === 1 ? 'bodyOdd' : 'bodyEven') + ' bodyLR1'}>
                        <div className='bodyLRInner'>
                          {(qual.detail !== undefined) && (qual.detail !== null) ? <span className='bodyA' onClick={() => actionHandler('qualifications', q)}>{qual.name}</span> : <span>{qual.name}</span>}
                          {(qual.when !== undefined) && (qual.when !== null) ? <span className='bodyRight'>{qual.when}</span> : null}
                        </div>
                      </div>
                    </>;
            }
          }
        } else if(style.body.qualifications === 'grid') {
          let elements = null;
          for(const i in resume.qualifications._list) {
            let q = resume.qualifications._list[i];
            let qual = resume.qualifications[q];
            if((qual !== undefined) && (qual !== null) && (qual.name !== undefined) && (qual.name !== null)) {
              elements = <>
                          {elements}
                          <div className='bodyGridElement'>
                            {(qual.detail !== undefined) && (qual.detail !== null) ? <div className='bodyGridElementLarge bodyA' onClick={() => actionHandler('qualifications', q)}>{qual.name}</div> : <div className='bodyGridElementLarge'>{qual.name}</div>}
                            <div className='bodyGridElementSmall'>{(qual.when !== undefined) && (qual.when !== null) ? qual.when : null}</div>
                          </div>
                        </>;
            }
          }
          ret = <>{ret}<div className='bodyGrid1'><div className='bodyGrid'>{elements}</div></div></>;
        }
      } else {
        let qual = resume.qualifications[detail];
        let det = null;
        if((qual.detail !== undefined) && (qual.detail !== null)) det = <><p /><div className='bodyText'>{v1BuildDetails(qual.detail, resume, actionHandler)}</div></>;
        ret = <>
                <h1>{qual.name}</h1>
                <div className='bodyLR1'><div className='bodyLRInner'><span>When</span><span className='bodyRight'>{qual.when}</span></div></div>
                {det}
              </>;
      }
      break;

    case 'references':
      if(detail === null) {
        ret = <h1>References</h1>;
        if((style.body.references === undefined) || (style.body.references === null) || (style.body.references === 'list')) {
          for(const i in resume.references._list) {
            let r = resume.references[resume.references._list[i]];
            if((r !== undefined) && (r !== null)) {
              ret = <>
                      {ret}
                      <div className='bodyLR1'>
                        <div className='bodyLRInner'>
                          <span className='bodyA' onClick={() => actionHandler('references', resume.references._list[i])}>{r.name}</span>
                          <span className='bodyRight'></span>
                        </div>
                      </div>
                    </>;
            }
          }
        } else if(style.body.references === 'grid') {
          let elements = null;
          for(const i in resume.references._list) {
            let r = resume.references[resume.references._list[i]];
            if((r !== undefined) && (r !== null)) {
              elements = <>
                          {elements}
                          <div className='bodyGridElement'>
                            <div className='bodyGridElementLarge'><span className='bodyA' onClick={() => actionHandler('references', resume.references._list[i])}>{r.name}</span></div>
                            <div className='bodyGridElementSmall'>{(r.email !== undefined) && (r.email !== null) ? r.email : null}</div>
                          </div>
                         </>;
            }
          }
          ret = <>{ret}<div className='bodyGrid1'><div className='bodyGrid'>{elements}</div></div></>;
        }
      } else {
        let r = resume.references[detail];
        let email = null;
        if((r.email !== undefined) && (r.email !== null)) email = <div className='bodyLRInner'><span>Email</span><span className='bodyRight'><a href={'mailto:' + r.email}>{r.email}</a></span></div>;
        let phone = null;
        if((r.phone !== undefined) && (r.phone !== null)) phone = <div className='bodyLRInner'><span>Phone</span><span className='bodyRight'>{r.phone}</span></div>;
        let det = null;
        if((r.detail !== undefined) && (r.detail !== null)) det = <div className='bodyLRInner'><span>Scope</span><span className='bodyRight'>{v1BuildDetails(r.detail, resume, actionHandler)}</span></div>;
        ret = <>
                <h1>{r.name}</h1>
                  <div className='bodyLR1'>
                    {email}
                    {phone}
                    {det}
                  </div>
              </>;
      }
      break;

    case 'note':
      if((resume.notes !== undefined) && (resume.notes !== null)) {
        let note = resume.notes[detail];
        if((note !== undefined) && (note !== null)) {
          if((note.type === undefined) || (note.type === null) || (note.type === 'page')) {
            ret = <>
                    <h1>{note.name}</h1>
                    <div className='bodyLR1'>{v1BuildDetails(note.detail, resume, actionHandler)}</div>
                  </>;
          } else if(note.type === 'popup') {
            // To be continued...
          }
        }
      }
      break;

    case 'search':
      ret = <h1>Showing results for {show.searchString}</h1>;
      if(detail.length === 0) {
        ret = <>{ret}<div className='bodyLR1'><span>No results found.</span><span></span></div></>;
      } else {
        if((style.body.search === undefined) || (style.body.search === null) || (style.body.search === 'list')) {
          let row = 0;
          for(const i in detail) {
            let e = detail[i];
            row++;
            switch(e.type) {
              case 'home':
                ret = <>
                        {ret}
                        <div className={((row % 2) === 1 ? 'bodyOdd' : 'bodyEven') + ' bodyLR1'}>
                          <div className='bodyLRInner'>
                            <span className='bodyA' onClick={() => actionHandler('home', null)}>Home</span>
                            <span>Home</span>
                          </div>
                        </div>
                      </>;
                break;

              case 'skills':
                ret = <>
                        {ret}
                        <div className={((row % 2) === 1 ? 'bodyOdd' : 'bodyEven') + ' bodyLR1'}>
                          <div className='bodyLRInner'>
                            <span className='bodyA' onClick={() => actionHandler('skills', e.detail)}>{(e.detail === null) ? 'Skills' : e.detail}</span>
                            <span>Skills</span>
                          </div>
                        </div>
                      </>;
                break;

              case 'roles':
                ret = <>
                        {ret}
                        <div className={((row % 2) === 1 ? 'bodyOdd' : 'bodyEven') + ' bodyLR1'}>
                          <div className='bodyLRInner'>
                            <span className='bodyA' onClick={() => actionHandler('roles', e.detail)}>{(e.detail === null) ? 'Roles' : e.detail}</span>
                            <span>Roles</span>
                          </div>
                        </div>
                      </>;
                break;

              case 'experience':
                ret = <>
                        {ret}
                        <div className={((row % 2) === 1 ? 'bodyOdd' : 'bodyEven') + ' bodyLR1'}>
                          <div className='bodyLRInner'>
                            <span className='bodyA' onClick={() => actionHandler('experience', e.detail)}>{(e.detail === null) ? 'Experience' : resume.experience[e.detail].name}</span>
                            <span>Experience</span>
                          </div>
                        </div>
                      </>;
                break;

              case 'qualifications':
                ret = <>
                        {ret}
                        <div className={((row % 2) === 1 ? 'bodyOdd' : 'bodyEven') + ' bodyLR1'}>
                          <div className='bodyLRInner'>
                            <span className='bodyA' onClick={() => actionHandler('qualifications', e.detail)}>{resume.qualifications[e.detail].name}</span>
                            <span>Note</span>
                          </div>
                        </div>
                      </>;
                break;

              case 'notes':
                ret = <>
                        {ret}
                        <div className={((row % 2) === 1 ? 'bodyOdd' : 'bodyEven') + ' bodyLR1'}>
                          <div className='bodyLRInner'>
                            <span className='bodyA' onClick={() => actionHandler('note', e.detail)}>{resume.notes[e.detail].name}</span>
                            <span>Note</span>
                          </div>
                        </div>
                      </>;
                break;

              default:
            }
          }
        } else if(style.body.search === 'grid') {
          let elements = null;
          for(const i in detail) {
            let e = detail[i];
            switch(e.type) {
              case 'home':
                elements = <>
                            {elements}
                            <div className='bodyGridElement'>
                              <div className='bodyGridElementLarge bodyA' onClick={() => actionHandler('home', null)}>Home</div>
                              <div className='bodyGridElementSmall'>Home</div>
                            </div>
                           </>;
                break;

              case 'skills':
                elements = <>
                            {elements}
                            <div className='bodyGridElement'>
                              <div className='bodyGridElementLarge bodyA' onClick={() => actionHandler('skills', e.detail)}>{(e.detail === null) ? 'Skills' : e.detail}</div>
                              <div className='bodyGridElementSmall'>Skills</div>
                            </div>
                           </>;
                break;

              case 'roles':
                elements = <>
                            {elements}
                            <div className='bodyGridElement'>
                              <div className='bodyGridElementLarge bodyA' onClick={() => actionHandler('roles', e.detail)}>{(e.detail === null) ? 'Roles' : e.detail}</div>
                              <div className='bodyGridElementSmall'>Roles</div>
                            </div>
                           </>;
                break;

              case 'experience':
                elements = <>
                            {elements}
                            <div className='bodyGridElement'>
                              <div className='bodyGridElementLarge bodyA' onClick={() => actionHandler('experience', e.detail)}>{(e.detail === null) ? 'Experience' : resume.experience[e.detail].name}</div>
                              <div className='bodyGridElementSmall'>Experience</div>
                            </div>
                           </>;
                break;

              case 'notes':
                elements = <>
                            {elements}
                            <div className='bodyGridElement'>
                              <div className='bodyGridElementLarge bodyA' onClick={() => actionHandler('note', e.detail)}>{resume.notes[e.detail].name}</div>
                              <div className='bodyGridElementSmall'>Note</div>
                            </div>
                           </>;
                break;

              default:
            }
          }

          ret = <>{ret}<div className='bodyGrid1'><div className='bodyGrid'>{elements}</div></div></>;
        }
      }
      break;

    default:
  }

  return ret;
}

function notSupported() {
  return <span className="bodyNotSupported">This resume is not supported by this version of the web app.</span>;
}

export default function Body({show, resume, style, actionHandler}) {
  useEffect(() => {
    if((show.scroll !== undefined) && (show.scroll !== null)) {
      let id = document.getElementById('body');
      if((id !== undefined) && (id !== null)) id.scrollTop = show.scroll;
    }
  });

  if((resume.name === undefined) || (resume.name === null)) return null;
  if((show === undefined) || (show === null)) return null;

  if((style.body === undefined) || (style.body === null)) style.body = {};

  let ret = null;
  if((resume.version === undefined) || (resume.version === null) || (resume.version === 1)) ret  = v1Process(show, resume, style, actionHandler);
  else ret = notSupported();

  return <div id='body' className='body'>{ret}</div>;
}
