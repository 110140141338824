import {useState} from 'react';
import './Utility.css';

var backImg = 'back.png';
var forwardImg = 'forward.png';
var homeImg = 'home.png';
var listImg = 'list.png';
var gridImg = 'grid.png';

export default function Utility({style, show, breadcrumbState, actionHandler}) {
  const [search, setSearch] = useState({text: ''});

  let space = <span className='utilitySpace'></span>;
  let home = null;
  let back = null;
  let forward = null;
  let list = null;
  let grid = null;
  let input = <input className='utilityText' type='text' value={search.text} onChange={(event) => handleChange(event)} />;
  let go = <input type='submit' value='Search' onClick={(event) => doSearch(event)} />;

  function handleChange(event) {
    setSearch({text: event.target.value});
  }

  function doSearch(event) {
    event.preventDefault();
    actionHandler('search', search.text);
  }

  if(breadcrumbState.index > 0) {
    back = <img className='utilityActive' src={backImg} alt='back' onClick={() => actionHandler('back', null)} />;
  } else {
    back = <img className='utilityInactive' src={backImg} alt='back' />;
  }

  if(breadcrumbState.index < breadcrumbState.top) {
    forward = <img className='utilityActive' src={forwardImg} alt='forward' onClick={() => actionHandler('forward', null)} />;
  } else {
    forward = <img className='utilityInactive' src={forwardImg} alt='forward' />;
  }

  if(show.what === 'home') home = <img className='utilityInactive' src={homeImg} alt='home' />;
  else home = <img className='utilityActive' src={homeImg} alt='home' onClick={() => actionHandler('home', null)} />;

  if((show.what === 'home') || ((show.what !== 'search') && (show.detail !== null))) {
    list = <img className='utilityInactive' src={listImg} alt='list' />;
    grid = <img className='utilityInactive' src={gridImg} alt='grid' />;
  } else if((style.body === undefined) || (style.body === null) || (style.body[show.what] === undefined) || (style.body[show.what] === null) || (style.body[show.what] === 'list')) {
    list = <img className='utilityInactive' src={listImg} alt='list' />;
    grid = <img className='utilityActive' src={gridImg} alt='grid' onClick={() => actionHandler('style', 'grid')} />;
  } else {
    list = <img className='utilityActive' src={listImg} alt='list' onClick={() => actionHandler('style', 'list')} />;
    grid = <img className='utilityInactive' src={gridImg} alt='grid' />;
  }

  return <div className='utilityContainer'>
          <div className='utilityNavi'>
            {back}
            {space}
            {forward}
            {space}
            {home}
            {space}
            {list}
            {space}
            {grid}
          </div>
          <div>
            <form>
              {input}
              {space}
              {go}
            </form>
          </div>
         </div>;
}
