import './Header.css';

export default function Header({resume, style, actionHandler}) {
  if((resume.name === undefined) || (resume.name === null)) return null;
  let skills = ((resume.skills !== undefined) && (resume.skills !== null)) ? <li onClick={() => {document.getElementById('menu-toggle').checked = false; actionHandler('skills', null)}}>Skills</li> : null;
  let roles = ((resume.roles !== undefined) && (resume.roles !== null)) ? <li onClick={() => {document.getElementById('menu-toggle').checked = false; actionHandler('roles', null)}}>Roles</li> : null;
  let experience = ((resume.experience !== undefined) && (resume.experience !== null)) ? <li onClick={() => {document.getElementById('menu-toggle').checked = false; actionHandler('experience', null)}}>Experience</li> : null;
  let qualifications = ((resume.qualifications !== undefined) && (resume.qualifications !== null)) ? <li onClick={() => {document.getElementById('menu-toggle').checked = false; actionHandler('qualifications', null)}}>Qualifications</li> : null;
  let references = ((resume.references !== undefined) && (resume.references !== null)) ? <li onClick={() => {document.getElementById('menu-toggle').checked = false; actionHandler('references', null)}}>References</li> : null;

  return (
    <section className='top-nav'>
      <div className='headerA' onClick={() => actionHandler('home', null)}>{resume.name}</div>
      <input id='menu-toggle' type='checkbox' />
      <label className='menu-button-container' htmlFor='menu-toggle'>
        <div className='menu-button'></div>
      </label>
      <ul className='menu'>
        {skills}
        {roles}
        {experience}
        {qualifications}
        {references}
      </ul>
    </section>
  );
}
